import * as React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkBreaks from 'remark-breaks'
import classnames from 'classnames'
import * as styles from './Markdown.module.scss'

type Props = {
  className?: any
  noParagraphs?: boolean
  children: any
  escapeHtml?: boolean
  readMore?: boolean
}

const linkTarget = (url = '') => {
  let returnTarget
  const localUrl = url?.split('?')?.length > 0 ? url.split('?')[0] : ''

  const isNew = localUrl.substring(0, 4) === 'http'
  const isSpotifyUri = localUrl.substring(0, 7) === 'spotify'
  const isDownload = localUrl.substring(0, 9) === 'data:text'
  const isMail = localUrl.startsWith('mailto')
  const isPhone = localUrl.startsWith('tel')
  const isPdf = localUrl.substring(-4, 4) === '.pdf'

  const isExternal =
    isNew || isMail || isPhone || isDownload || isSpotifyUri || isPdf

  if (isExternal) {
    returnTarget = '_blank'
  }

  return returnTarget
}

const LinkRenderer = (props: any) => {
  const { children, node } = props
  const { url } = node

  const target = linkTarget(url)

  return (
    <a
      href={url}
      target={target}
      rel={target === '_blank' ? 'noopener noreferrer' : ''}
    >
      {children}
    </a>
  )
}

const Markdown = ({ className = '', children }: Props): JSX.Element => {
  const plugins = [remarkBreaks]

  return (
    <div className={classnames(styles.markdown, className)}>
      <ReactMarkdown
        components={{
          a(props: any) {
            const { href, children } = props
            const target = linkTarget(href)
            return (
              <a
                href={href}
                target={target}
                rel={target === '_blank' ? 'noopener noreferrer' : ''}
              >
                {children}
              </a>
            )
          },
        }}
      >
        {children}
      </ReactMarkdown>
    </div>
  )
}

export default Markdown
