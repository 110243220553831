import React, { FC } from 'react'

import * as styles from './BackDrop.module.scss'

interface Props {
  active: boolean
  handleClick: any
  lower?: boolean
}

const BackDrop: FC<Props> = ({ active, lower, handleClick }) => {
  return (
    <button
      tabIndex={active ? 0 : -1}
      className={[
        styles.backdrop,
        active ? styles.active : ' ',
        lower ? styles.lower : '',
      ].join(' ')}
      onClick={handleClick}
      aria-label="Close"
    />
  )
}

export default BackDrop
