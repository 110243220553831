// extracted by mini-css-extract-plugin
export var active = "Announcement-module--active--2ec++";
export var bigMedia = "Announcement-module--bigMedia--ro+yU";
export var blueButton = "Announcement-module--blueButton--GRpGq";
export var button = "Announcement-module--button--HD6pk";
export var buttons = "Announcement-module--buttons--nUbCT";
export var close = "Announcement-module--close--3EAGx";
export var excerpt = "Announcement-module--excerpt--NwTtQ";
export var fadeIn = "Announcement-module--fadeIn--EPtss";
export var from = "Announcement-module--from--fczus";
export var heading = "Announcement-module--heading--gwkbc";
export var icon = "Announcement-module--icon--XQ1sR";
export var inner = "Announcement-module--inner--ndjSz";
export var left = "Announcement-module--left--jtKtY";
export var loaded = "Announcement-module--loaded--OZdU6";
export var markdown = "Announcement-module--markdown--QnRbK";
export var mediaWrapper = "Announcement-module--mediaWrapper--mQ1NM";
export var popup = "Announcement-module--popup--UFW+1";
export var right = "Announcement-module--right--QQrxw";
export var sidebar = "Announcement-module--sidebar--nvQg2";
export var text = "Announcement-module--text--lNape";
export var title = "Announcement-module--title--nLlM0";
export var top = "Announcement-module--top--TJ72v";