import React, { useEffect, useState } from 'react'

import * as styles from './Announcement.module.scss'

import classnames from 'classnames'

import SbEditable from 'storyblok-react'
import Image from 'src/components/UI/Image/Image'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import Markdown from 'src/components/UI/Markdown/Markdown'
import BackDrop from '../BackDrop/BackDrop'
const Announcement = ({ blok }: any) => {
  const [popupActive, setPopupActive] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)
  const [bigImageLoaded, setBigImageLoaded] = useState(false)
  const [bigActive, setBigActive] = useState(false)
  const { from_name, title, text, from_icon, media, cta } = blok
  console.log(cta)
  useEffect(() => {
    setTimeout(() => {
      setPopupActive(true)
    }, 1000)
  }, [])
  useEffect(() => {
    if (bigActive) {
      document.documentElement.classList.add('no-scroll')
    } else {
      document.documentElement.classList.remove('no-scroll')
    }
    return () => {
      document.documentElement.classList.remove('no-scroll')
    }
  }, [bigActive])

  return (
    <SbEditable content={blok}>
      <div
        className={classnames(
          styles.popup,
          popupActive && !bigActive ? styles.active : ''
        )}
      >
        <div onClick={() => setBigActive(true)} className={styles.top}>
          <div className={styles.left}>
            {from_name && (
              <div className={styles.from}>
                {from_icon && from_icon.filename && (
                  <div className={styles.icon}>
                    <Image
                      className={imageLoaded ? styles.loaded : ''}
                      src={from_icon.filename}
                      alt={from_icon.title}
                    />
                  </div>
                )}
                <span>{from_name}</span>
              </div>
            )}
            {title && <span className={styles.title}>{title}</span>}
            {text && <p className={styles.text}>{text}</p>}
          </div>
          <div className={styles.right}>
            <div className={styles.mediaWrapper}>
              {media && media.filename && (
                <Image
                  onImageLoaded={() => {
                    setImageLoaded(true)
                  }}
                  className={imageLoaded ? styles.loaded : ''}
                  src={media.filename}
                  alt={media.title}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.buttons}>
          {cta &&
            cta.length > 0 &&
            cta.map((blok: any) =>
              blok.component === 'big_announcement' ? (
                <button
                  onClick={() => setBigActive(true)}
                  className={styles.button}
                >
                  {blok.cta_title}
                </button>
              ) : (
                <LinkComponent
                  className={styles.button}
                  key={blok._uid}
                  to={blok.url}
                >
                  <span>{blok.title}</span>
                </LinkComponent>
              )
            )}
          <button
            onClick={() => {
              setPopupActive(false)
            }}
            className={styles.button}
          >
            <span>Stäng</span>
          </button>
        </div>
      </div>
      <BackDrop
        handleClick={() => {
          setBigActive(false)
          setPopupActive(false)
        }}
        active={bigActive}
      />

      {cta && cta[0] && cta[0].component === 'big_announcement' && (
        <div
          className={classnames(styles.sidebar, bigActive ? styles.active : '')}
        >
          <div className={styles.inner}>
            <div className={styles.heading}>
              <div className={styles.bigMedia}>
                {cta[0].media && cta[0].media.filename && (
                  <Image
                    onImageLoaded={() => {
                      setBigImageLoaded(true)
                    }}
                    className={bigImageLoaded ? styles.loaded : ''}
                    src={cta[0].media.filename}
                    alt={cta[0].media.title}
                  />
                )}
              </div>
              <button
                onClick={() => {
                  setBigActive(false)
                  setPopupActive(false)
                }}
                className={styles.close}
              >
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5157 13.2405L3.73289 20.0233L1.03058 17.321L7.75935 10.5922L0.598204 3.43109L3.16541 0.86389L10.3266 8.02503L17.1904 1.16114L19.8117 3.78239L13.0018 10.5922L20.19 17.7804L17.6228 20.3476L10.5157 13.2405Z"
                    fill="#02277C"
                  />
                </svg>
              </button>
            </div>
            {cta[0].title && <h3>{cta[0].title}</h3>}
            {cta[0].excerpt && (
              <p className={styles.excerpt}>{cta[0].excerpt}</p>
            )}
            {cta[0].text && (
              <Markdown className={styles.markdown}>{cta[0].text}</Markdown>
            )}
          </div>
          {cta[0].cta &&
            cta[0].cta.length > 0 &&
            cta[0].cta[0] &&
            cta[0].cta[0].url &&
            cta[0].cta[0].url.cached_url && (
              <LinkComponent
                className={classnames('button', styles.blueButton)}
                to={cta[0].cta[0].url.cached_url}
              >
                <span> {cta[0].cta[0].title}</span>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="30"
                    viewBox="0 0 40 30"
                    fill="none"
                  >
                    <path
                      d="M24.825 0.0999756L39.075 14.35V15.65L24.825 29.9L21.925 27L32.025 17H0.924988V13.05H32.075L21.925 2.99998L24.825 0.0999756Z"
                      fill="#D9D9D9"
                    />
                  </svg>
                </div>
              </LinkComponent>
            )}
        </div>
      )}
    </SbEditable>
  )
}

export default Announcement
