import React, { useState } from 'react'

import * as styles from './Merchandise.module.scss'

import classnames from 'classnames'

import SbEditable from 'storyblok-react'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import Image from 'src/components/UI/Image/Image'
const Merchandise = ({ blok }: any) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const { title, description, items, cta } = blok
  return (
    <SbEditable content={blok}>
      <section className={classnames(styles.wrapper)}>
        <div className={styles.info}>
          {title && <h2 className="heading">{title}</h2>}
          {description && <p className="preamble">{description}</p>}
        </div>
        <div
          className={styles.items}
          style={{ gridTemplateColumns: `repeat(${items.length}, 1fr)` }}
        >
          {items &&
            items.length > 0 &&
            items.map((blok: any) => (
              <SbEditable content={blok}>
                <LinkComponent
                  className={styles.item}
                  to={blok.link.cached_url}
                >
                  <div className={styles.media}>
                    {blok.media && (
                      <Image
                        onImageLoaded={() => {
                          setImageLoaded(true)
                        }}
                        className={imageLoaded ? styles.loaded : ''}
                        src={blok.media.filename}
                        alt={blok.media.title}
                      />
                    )}
                  </div>
                  {blok.title && <h4>{blok.title}</h4>}
                </LinkComponent>
              </SbEditable>
            ))}
        </div>
        <div className={styles.bottom}>
          <div className={styles.bg}></div>
          <div>
            <LinkComponent to={cta[0].url.cached_url} className="button">
              <span>{cta[0].title}</span>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="30"
                  viewBox="0 0 40 30"
                  fill="none"
                >
                  <path
                    d="M24.825 0.0999756L39.075 14.35V15.65L24.825 29.9L21.925 27L32.025 17H0.924988V13.05H32.075L21.925 2.99998L24.825 0.0999756Z"
                    fill="#D9D9D9"
                  />
                </svg>
              </div>
            </LinkComponent>
          </div>
        </div>
      </section>
    </SbEditable>
  )
}

export default Merchandise
